<template>
  <b-steps
    v-model="activeStep"
    :type="type"
    :has-navigation="false"
    :clickable="false"
  >
    <b-step-item
      :label="step1"
      icon="account-key"
    />
    <b-step-item
      :label="step2"
      icon="account-key"
    />
  </b-steps>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    step1 () {
      return `Dispatched at ${moment(this.doc.progress.dispatched.at).format('LL')}`
    },
    step2 () {
      const end = this.doc.progress.repaired.at ? moment(this.doc.progress.repaired.at) : moment()
      const duration = moment.duration(end.diff(moment(this.doc.progress.dispatched.at)))
      const days = duration.asDays()

      return this.doc.progress.repaired.at
        ? `Repair at the supplier took ${days} days`
        : `Part is being at the supplier for ${days} days now...`
    },
    activeStep () {
      return this.doc.progress.repaired.at ? 1 : 0
    },
    type () {
      return this.doc.progress.repaired.at ? 'is-success' : 'is-warning'
    }
  }
}
</script>
